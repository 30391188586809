import React, { useState } from 'react';
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import NavBarMenu from "../../components/NavBarMenu";
import CoverImage from '../../components/CoverImage';
import ContactForm from '../../components/ContactForm';
import contactUs from '../../../assets/contactUs.jpg';
import generateMeta from '../../utils/meta';

function generateTotalCategoryHierarchy(node) {
  if (!node) return [];
  let prefix = [];
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalCategoryHierarchy(node.parentCategory);
  }
  prefix.push(node);
  return prefix;
}

function generateTotalSlug(node) {
  if (!node) return "";
  let prefix = "";
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalSlug(node.parentCategory);
  }
  return prefix + "/" + node.slug;
}

const ContactPage = ({ data, pageContext, t }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // i18n used for translation
  i18n(pageContext.locale);

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  const [formValue, setFormValue] = useState({
    email: '',
    message: '',
  });

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title>Contact</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <CoverImage
        imageDesktop={contactUs}
        imageMobile={contactUs}
        title="Contact"
        altDesktop="image contact"
        altMobile="image contact"
      />
      <div className="max-w-screen-md mx-auto">
        <ContactForm
          firstLabel="Votre email"
          secondLabel="Votre message"
          setFormValue={setFormValue}
          formValue={formValue}
        />
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
      edges {
        node {
          categoryName
          categoryEncartTitle
          slug
          parentCategory {
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/contact.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
`;
