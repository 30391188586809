import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactLaunched } from '../../state/reducer';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

const ContactForm = ({ firstLabel, formValue, secondLabel, setFormValue }) => {
  const dispatch = useDispatch();
  const { contactSuccess, contactLoading, contactError } = useSelector((state) => (
    {
      contactSuccess: state.getIn(['root', 'contactSuccess']),
      contactLoading: state.getIn(['root', 'contactLoading']),
      contactError: state.getIn(['root', 'contactError'])
    }
  ));

  const initialValues = {
    email: formValue.email,
    message: formValue.message,
  };
  const ValidationSchema = Yup.object({
    email: Yup.string()
      .required("Mail est obligatoire")
      .email("Mauvais format de mail"),
    message: Yup.string()
      .required("Message obligatoire")
  });

  const sendMessage = () => {
    dispatch(contactLaunched(formValue));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={sendMessage}
    >
      {({ errors, touched, handleChange }) => (
        <Form className="flex flex-col items-center pt-4 w-full">
          <div className="pb-8 w-9/12">
            <label className="text-base text-grey-lightDark pb-4">{firstLabel}</label>
            <Field
              id="email"
              name="email"
              type="email"
              className="appearance-none w-full block pb-2 py-3 px-4 focus:outline-none shadow-sm focus:ring-orange-light focus:border-orange-dark border border-grey-lightDark"
              onChange={(e) => {
                handleChange(e);
                setFormValue((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.value
                }));
              }}
            />
            {errors.email && touched.email ? (
              <div className="text-orange-darkest">{errors.email}</div>
            ) : null}

          </div>
          <div className="pb-8 w-9/12">
            <label className="appearance-none text-base text-grey-lightDark pb-4">{secondLabel}</label>
            <Field
              as="textarea"
              id="message"
              name="message"
              style={{ height: 200 }}
              className="text-area w-full block h-full pb-2 py-3 px-4 shadow-sm focus:outline-none focus:ring-orange-light focus:border-orange-dark border border-grey-lightDark"
              onChange={(e) => {
                handleChange(e);
                setFormValue((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.value
                }));
              }}
            />
            {errors.message && touched.message ? (
              <div className="text-orange-darkest">{errors.message}</div>
            ) : null}
          </div>
          {(contactSuccess) && <p className="pb-4">Votre message a bien été envoyé</p>}
          {(contactError) && <p className="text-red pb-4">Une erreur est survenue, veuillez réessayer</p>}
          <div className="pb-8">
            <button
              type="submit"
              className="bg-orange-dark py-2 px-6 md:px-10 text-xxm md:text-sm outline-neutral-50 text-white focus:outline-neutral-50"
              disabled={errors.message || errors.email}
            >
              {(!contactLoading) ? 'Envoyer ma demande' : 'Envoi en cours...'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
};

export default ContactForm

